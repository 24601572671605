<template>
<v-container fluid>
  <v-btn @click="$router.push({ name: 'adminCostCenters' })" rounded color="primary">       <v-icon
        left
        small
        dark
      >
        fa-arrow-left
      </v-icon>Zurück
</v-btn>
<v-btn color="primary"        rounded  v-if="$store.state.UserInfo.adm === 1"
        dark @click="$store.dispatch('recalcFlightCost')">
                Preise neu berechnen
</v-btn>
   <CURDTable tableId="costCenterTransactionsTable" :title="`Transaktionen für Kostenstelle ${computedCostCenterName}`"
    :dataSchema="dataSchemaCostCenterTransactions" loadDataFunction="loadTransactionsByCostCenter" :loadDataFunctionPayload="computedCostCenterID"
              storeData="CostCenterTransactionList" showNumberOfItems showSearch enableDateSpan></CURDTable>
    <CURDTable tableId="costCenterPaybackTable" :title="`Erstattungen für Kostenstelle ${computedCostCenterName}`"
    :dataSchema="dataSchemaCostCenterPaybacks" loadDataFunction="loadPaybackByCostCenter" :loadDataFunctionPayload="computedCostCenterID"
              storeData="CostCenterPaybackList" showNumberOfItems showSearch info enableDateSpan></CURDTable>
              <FlightList v-bind:flightsExternal="computedCostCenterFlightList" :titleExternal='`Flüge der Kostenstelle ${computedCostCenterName}`' :showPrice="true"
          :denseExternal="true"  v-if="computedCostCenterFlightList.length > 0" style="padding-top: 30px" @toggle-data-reload="loadFlightData"></FlightList>
 </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
import FlightList from '../../components/FlightList.vue'
export default {
  name: 'AdminCostCentersInfo',
  data: () => ({
    dataSchemaCostCenterTransactions: [
      { dataId: 'transactionID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userID', type: 'autocomplete', text: 'Mitglied', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'amount', type: 'float', steps: 0.01, text: 'Betrag', customInputLabel: 'Betrag   (NEGATIV: Rechnung an Mitglied; POSITIV: Gutschrift)', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'date', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'paymentMethod', type: 'autocomplete', text: 'Zahlart', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PaymentTypeList', autocompleteValue: 'paymenttypeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'costCenter', type: 'autocomplete', text: 'Kostenstelle', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'CostCenterList', autocompleteValue: 'costCenterID', autocompleteText: 'name', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ],
    dataSchemaCostCenterPaybacks: [
      { dataId: 'receiptID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'creatorID', type: 'autocomplete', text: 'Ersteller', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'creationTime', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' },
      { dataId: 'amount', type: 'float', steps: 0.01, text: 'Betrag', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'category', type: 'autocomplete', text: 'Kategorie', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PaybackCategoryList', autocompleteValue: 'typeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'costCenter', type: 'autocomplete', text: 'Kostenstelle', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'CostCenterList', autocompleteValue: 'costCenterID', autocompleteText: 'name', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'paybackType', type: 'autocomplete', text: 'Art', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PaybackTypeList', autocompleteValue: 'categoryID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'paybackTo', type: 'autocomplete', text: 'An', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'approved', type: 'int', text: 'Status', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'approvedBy', type: 'autocomplete', text: 'Von', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'done', type: 'checkbox', text: 'Erledigt', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' },
      { dataId: 'withdrawID', type: 'text', text: 'Anhang', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'billingDate', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' }
    ]
  }),
  components: {
    CURDTable,
    FlightList
  },
  computed: {
    computedCostCenterID () {
      return this.$route.params.id
    },
    computedCostCenterName () {
      return this.$store.state.CostCenterList.filter(e => e.costCenterID === this.computedCostCenterID)[0].name
    },
    computedCostCenterFlightList () {
      return this.$store.state.CostCenterFlightList
    }
  },
  methods: {
    loadFlightData () {
      this.$store.dispatch('loadFlightsByCostCenter', { payload: { customPayload: this.computedCostCenterID } })
    }
  },
  async mounted () {
    this.loadFlightData()
  }

}
</script>
