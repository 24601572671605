import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
var qs = require('qs')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Initialisation: false,
    ApiErrorResponse: [],
    OnlineFlightList: [],
    AirportList: [],
    AdminInvoice: [],
    AdminContracts: [],
    AdminDatabaseFlights: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: 1
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: 1
      },
      {
        name: 'Eclair',
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: 7
      }
    ],
    AdminContractPaymentMethods: [
      { title: 'Lastschirft', paymentMethodID: 1 },
      { title: 'Überweisung', paymentMethodID: 2 },
      { title: 'Dauerauftrag', paymentMethodID: 3 }
    ],
    AdminContractPayments: [],
    AdminContractDocuments: [],
    AdminMemberData: [],
    AdminPaybackData: [],
    AdminAllTags: [],
    AdminMemberDataLoadTime: -1,
    Coupons: [],
    CouponStatus: [
      { title: 'Erstellt und nicht ausgegeben', statusID: 1 },
      { title: 'Ausgegeben und nicht abgeflogen', statusID: 2 },
      { title: 'Abgeflogen', statusID: 3 }
    ],
    Downloads: [],
    UserInfo: [],
    LaunchmethodList: [
      { title: 'Winde', launchID: 1 },
      { title: 'F-Schlepp', launchID: 2 },
      { title: 'Eigenstart', launchID: 6 },
      { title: 'Schleppflugzeug im Schleppzug', launchID: 3 },
      { title: 'Gummiseil', launchID: 4 },
      { title: 'Autoschlepp', launchID: 5 },
      { title: 'F-Schlepp extern (Segler)', launchID: 7 },
      { title: 'F-Schlepp extern (Schleppmaschine)', launchID: 8 }
    ],
    FlightTypeList: [
      { title: 'Normal', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 3 },
      { title: 'Schulung Einzeln', maxNumberOfPilots: 1, minNumberOfPilots: 1, typeID: 1 },
      { title: 'Schulung Doppelsitzig', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 2 },
      { title: 'Einweisung', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 4 },
      { title: 'Werkstattflug', maxNumberOfPilots: 1, minNumberOfPilots: 1, typeID: 5 },
      { title: 'Gutscheinflug', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 6 },
      { title: 'Gastflug', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 7 },
      { title: 'Überprüfung', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 8 },
      { title: 'Kommt', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 9 },
      { title: 'Geht', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 10 }
    ],
    PlaneTypeList: [
      { title: 'Segler', typeID: 1 },
      { title: 'Motorsegler', typeID: 2 },
      { title: 'Motorflugzeug', typeID: 3 },
      { title: 'Segler mit Motor', typeID: 4 },
      { title: 'Ultraleicht', typeID: 5 },
      { title: 'andere', typeID: 6 }
    ],
    CostCenterList: [],
    PilotList: [],
    PlaneList: [],
    PaymentTypeList: [
      { title: 'Standard', paymenttypeID: 1 },
      { title: 'Gutschein', paymenttypeID: 2 },
      { title: 'Gutschein, Rest Abbuchen', paymenttypeID: 3 },
      { title: 'Gutschein, Rest Bar', paymenttypeID: 4 },
      { title: 'Abbuchung, mehrere (geteilt)', paymenttypeID: 5 },
      { title: 'Verein zahlt', paymenttypeID: 6 },
      { title: 'Bar', paymenttypeID: 7 }
    ],
    MembershipTypeList: [
      { title: 'Regulär', typeID: 1 },
      { title: 'Fördermitglied', typeID: 2 },
      { title: 'Ehrenmitglied', typeID: 3 },
      { title: 'Nichtmitglied', typeID: 4 },
      { title: 'Anderer Flugverein (Fliegerlager)', typeID: 5 }
    ],
    PaybackCategoryList: [],
    PaybackTypeList: [
      { title: 'Rückzahlung Bar', categoryID: 1 },
      { title: 'Überweisung auf Konto', categoryID: 2 },
      { title: 'Verrechnung Fliegerkonto', categoryID: 3 },
      { title: 'Lastschrift', categoryID: 6 },
      { title: 'Rechnung an Händler überweisen', categoryID: 5 },
      { title: 'Sonnstiges nach Absprachen', categoryID: 4 }
    ],
    UserPayback: [],
    UserTransactions: [],
    UserBalance: [],
    UserLicenseRights: [],
    LicenseTypes: [],
    LicenseCategories: [
      { title: 'Fluglizenz', categoryID: 1 },
      { title: 'Lehrerlizenz', categoryID: 2 },
      { title: 'Medical', categoryID: 3 },
      { title: 'Züp', categoryID: 4 },
      { title: 'andere', categoryID: 5 }
    ],
    User: {
      userID: -1,
      auth: {
        username: '',
        password: '',
        authStr: ''
      }
    },
    MailingLists: [],
    paybackRequests: [],
    Statistic: [],
    PlanePartsList: [],
    PlaneDocsList: [],
    PlaneFlightReportList: [],
    PlaneDocsCategories: [
      { categoryID: 1, title: 'Handbuch' },
      { categoryID: 2, title: 'Wägebericht' },
      { categoryID: 3, title: 'Prüfbericht' },
      { categoryID: 4, title: 'Reperaturprotkoll' },
      { categoryID: 5, title: 'Freigabe' },
      { categoryID: 6, title: 'Form-One' },
      { categoryID: 7, title: 'Rechnung' },
      { categoryID: 8, title: 'Eintragungschein' }
    ],
    AllUserTransactions: [],
    CostCenterBalanceList: [],
    CostCenterFlightList: [],
    CostCenterTransactionList: [],
    CostCenterPaybackList: [],
    EventCategories: [
      { title: 'Termin', categoryID: 1 },
      { title: 'Fliegerlager', categoryID: 2 },
      { title: 'Raumbuchung', categoryID: 3 },
      { title: 'Event am Platz', categoryID: 4 },
      { title: 'andere', categoryID: 5 }
    ],
    UserDocsList: [],
    UserTags: [],
    UserDocsCategoriesList: [],
    AccessRoles: [
      { title: 'Nur Ich u. Vorstand', value: 100 },
      { title: 'Nur Vorstand', value: 1337 },
      { title: 'Jeder', value: 0 }
    ],
    TagTypeList: [
      { title: 'Normal', typeID: 1 }
    ]
  },
  mutations: {
    setApiErrorResponse: (state, Response) => {
      state.ApiErrorResponse = Response
    },
    deleteApiErrorResponse: (state) => {
      state.ApiErrorResponse = []
    },
    initialiseStore (state) {
      // local store
      if (Vue.$cookies.isKey('User')) {
        state.User = Vue.$cookies.get('User')
      }
    },
    savePilotList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
        newList[i].fullname = newList[i].firstname + ' ' + newList[i].lastname // Auto Generate Full-Name
      }
      state.PilotList = newList
    },
    savePlaneList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'hours') continue
          if (key === 'instructors') continue
          if (key === 'lastMotorStop') continue
          if (key === 'minutePrice') continue
          if (key === 'minutePriceGuest') continue
          if (key === 'minutePriceHonorary') continue
          if (key === 'minutePricePassive') continue
          if (key === 'minutePriceReduced') continue
          if (key === 'priceTimes') continue
          if (key === 'towPriceMinute') continue
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.PlaneList = newList
    },
    saveAllContracts: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'annualAmount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminContracts = newList
    },
    saveAllContractPayments: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminContractPayments = newList
    },
    saveAllContractDocuments: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminContractDocuments = newList
    },
    saveAllUserTransactions: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AllUserTransactions = newList
    },
    saveAllCoupons: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'value') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.Coupons = newList
    },
    saveAdminMemberData: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'balance') continue
          if (key === 'bankOffset') continue
          if (key === 'bankMinimum') continue
          if (!isNaN(value) && value !== null && value) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminMemberData = newList
      state.AdminMemberDataLoadTime = Date.now()
    },
    saveAdminPaybackData: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminPaybackData = newList
    },
    saveCostCenterList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.CostCenterList = newList
    },
    saveAirportList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AirportList = newList
    },
    saveFlightList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'totalCost') continue
          if (key === 'engineStart') continue
          if (key === 'engineStop') continue
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.OnlineFlightList = newList
    },
    saveMailingList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'users' || key === 'usersWO') {
            newList[i][key] = newList[i][key].split(',')
            for (var x = 0; x < newList[i][key].length; x++) {
              newList[i][key][x] = parseInt(newList[i][key][x])
            }
            // REMOVE NaN from Array
            newList[i][key] = newList[i][key].filter(function (val) { return !Number.isNaN(val) })

            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.MailingLists = newList
    },
    saveUserPaybackList: (state, newList) => {
      //  console.log(newList)
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            if (key === 'amount') {
              newList[i][key] = parseFloat(value).toFixed(2)
              continue
            }
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.UserPayback = newList
    },
    savePaybackRequests: (state, newList) => {
      //  console.log(newList)
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            if (key === 'amount') {
              newList[i][key] = parseFloat(value).toFixed(2)
              continue
            }
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.paybackRequests = newList
    },
    saveUserInfo: (state, newState) => {
      for (var [key, value] of Object.entries(newState)) {
        if (!isNaN(value) && value !== null) {
          newState[key] = parseInt(value)
        }
      }
      state.UserInfo = newState
    },
    savePlanePartList: (state, newState) => {
      for (var [key, value] of Object.entries(newState)) {
        if (!isNaN(value) && value !== null) {
          newState[key] = parseInt(value)
        }
      }
      state.PlanePartsList = newState
    },
    savePlaneDocsList: (state, newState) => {
      for (var [key, value] of Object.entries(newState)) {
        if (!isNaN(value) && value !== null) {
          newState[key] = parseInt(value)
        }
      }
      state.PlaneDocsList = newState
    },
    savePlaneFlightReportList: (state, newState) => {
      for (var [key, value] of Object.entries(newState)) {
        if (!isNaN(value) && value !== null) {
          newState[key] = parseInt(value)
        }
      }
      state.PlaneFlightReportList = newState
    },
    saveUserTransactions: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.UserTransactions = newList
    },
    saveAllCostCenterBalance: (state, newList) => {
      for (var [key, value] of Object.entries(newList)) {
        if (!isNaN(value) && value !== null && value.toString().indexOf('.') !== -1) {
          newList[key] = parseFloat(value).toFixed(2)
        }
      }
      state.CostCenterBalanceList = newList
    },
    saveDownloads: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.Downloads = newList
    },
    saveStatistics: (state, newList) => {
      state.Statistic = newList
    },
    saveBalanceList: (state, newList) => {
      for (var [key, value] of Object.entries(newList)) {
        if (!isNaN(value) && value !== null && value.toString().indexOf('.') !== -1) {
          newList[key] = parseFloat(value).toFixed(2)
        }
      }
      state.UserBalance = newList
    },
    savePaybackCategoryList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.PaybackCategoryList = newList
    },
    setUser: (state, User) => {
      Vue.$cookies.set('User', JSON.stringify(User))

      state.User.userID = User.userID
      state.User.auth.username = User.auth.username
      state.User.auth.password = User.auth.password
      state.User.auth.authStr = User.auth.authStr
    },
    logout: (state) => {
      state.User.userID = -1
      state.User.auth.username = ''
      state.User.auth.password = ''
      state.User.auth.authStr = ''

      Vue.$cookies.remove('User')
    },
    saveFlightsByCostCenter: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'totalCost' || key === 'launchCost' || key === 'flightTimeCost' || key === 'landingCost') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.CostCenterFlightList = newList
    },
    savePaybackByCostCenter: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.CostCenterPaybackList = newList
    },
    saveTransactionsByCostCenter: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.CostCenterTransactionList = newList
    },
    saveUserDocuments: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.UserDocsList = newList
    },
    saveAdminInvoice: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'amount') {
            newList[i][key] = parseFloat(value).toFixed(2)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminInvoice = newList
    },
    saveUserDocumentCategories: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.UserDocsCategoriesList = newList
    },
    saveUserLicenseRights: (state, newList) => {
      var tmpArray = []
      newList.forEach(plane => {
        var rightsNow = ''
        var rightsTomorrow = ''
        var rights3Month = ''
        for (const [key, value] of Object.entries(plane)) {
          if (typeof value === 'object' && value !== null) {
            if (value.length > 0) {
              var splitKey = key.split('_')
              var launchmethod = splitKey[0]
              var dateRange = splitKey[1]
              if (dateRange === '3m') {
                rights3Month = rights3Month.concat(launchmethod, ',')
              } else if (dateRange === 'T') {
                rightsTomorrow = rightsTomorrow.concat(launchmethod, ',')
              } else if (dateRange === undefined) {
                rightsNow = rightsNow.concat(launchmethod, ',')
              }
            }
          }
        }
        var tmpObject = {
          callsign: plane.callsign,
          planeName: plane.planeName,
          rightsToday: rightsNow,
          rightsTomorrow: rightsTomorrow,
          rights3Month: rights3Month
        }
        tmpArray.push(tmpObject)
      })
      console.log(tmpArray)
      state.UserLicenseRights = tmpArray
    },
    saveLicenseTypes: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.LicenseTypes = newList
    },
    saveAdminTags: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AdminAllTags = newList
    },
    saveUserTags: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.UserTags = newList
    }
  },
  actions: {
    async loadUserInfo ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_GET_FULL_PILOT_INFO + '?includeBankData=1&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveUserInfo', response.data.result)
          })
      }
    },
    async loadFlightTypeList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_FLIGHTTYPELIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveFlightTypeList', response.data.result)
          })
      }
    },
    async loadCostCenterList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_COST_CENTERS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveCostCenterList', response.data.result)
          })
      }
    },
    async loadMailingList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_MAILINGLISTS_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveMailingList', response.data.result)
          })
      }
    },
    async createMailingList ({ state, commit }, list) {
      if (state.User.userID !== -1) {
        if (typeof list.users === 'string' && list.users !== '') {
          list.users = list.users.join()
        }
        if (typeof list.usersWO === 'string' && list.usersWO !== '') {
          list.usersWO = list.usersWO.join()
        }
        return axios.post(process.env.VUE_APP_API_PATH_MAILINGLISTS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(list), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async deleteMailingList ({ state, commit }, payload) {
      if (state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_MAILINGLISTS_DELETE + '?listID=' + payload.id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async deletePlaneDocs ({ state, commit }, payload) {
      console.log(payload)
      if (state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_PLANE_DOCS_DELETE + '?documentID=' + payload.documentID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async loadDownloads ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_DOWNLOADS_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  console.log(response)
            commit('saveDownloads', response.data.result)
          })
      }
    },
    async loadPaybackCategory ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_PAYBACK_CATEGORY + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('savePaybackCategoryList', response.data.result)
          })
      }
    },
    async loadPilotList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_PILOTLIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  console.log(response)
            commit('savePilotList', response.data.result)
          })
      }
    },
    async loadPlaneList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_PLANELIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('savePlaneList', response.data.result)
          })
      }
    },
    async loadAirportList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_AIRPORT_LIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveAirportList', response.data)
          })
      }
    },
    async loadFlightList ({ commit, state }, year) {
      //  console.log('Loading Flights from Database....')
      var dStart = null
      var dEnd = null
      if (this.state.User.userID !== -1) {
        if (year === null || typeof year === 'undefined') { // Wenn kein year, dann nur Dieses Jahr anfang - unendlich
          dStart = new Date().getFullYear() + '-01-01'
          dEnd = null
        } else if (year.toString().length === 4) { // Wenn year eine Zahl mit 4 Ziffern (JAHR), dann nur year (Jahr) anfang - dEnde (Jahr)
          dStart = year + '-01-01'
          dEnd = year + '-12-31' // Diese Zeile nicht vertauschen!!!
        }
        axios
          .get(process.env.VUE_APP_API_PATH_USER_FLIGHTLIST + '?dStart=' + dStart + (dEnd != null ? '&dEnd=' + dEnd : '') + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  console.log(response)
            commit('saveFlightList', response.data.result)
          })
      }
    },
    async loadUserBalance ({ commit, state }, id) {
      if (id === undefined) id = state.User.userID
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_USER_BALANCE_GET + '?userID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveBalanceList', response.data.result)
          })
      }
    },
    async loadFlightListByDateSpan ({ commit, state }, date) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_FLIGHTLISTS_BY_DATE_SPAN + '?dStart=' + date.from + '&dEnd=' + date.to + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async loadUserTransactionsList ({ commit, state }, id) {
      if (id === undefined) id = state.User.userID
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_USER_TRANSACTIONSLIST + '?userID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  console.log(response)
            commit('saveUserTransactions', response.data.result)
          })
      }
    },
    async initialiseAction ({ dispatch, state }) {
      await dispatch('checkToken', state.User)
      if (state.User.userID !== -1) {
        await dispatch('loadUserInfo')
        await dispatch('loadAirportList')
        await dispatch('loadPaybackCategory')
        await dispatch('loadPilotList')
        await dispatch('loadPlaneList')
        await dispatch('loadFlightList')
        await dispatch('loadCostCenterList')
        await dispatch('loadLicenseTypes')
        await dispatch('loadUserDocumentsCategories')
      }
    },
    async validatePlane ({ state }, plane) {
      //  console.log(qs.stringify(plane))
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updatePlane ({ state, dispatch }, plane) {
      return axios.post(process.env.VUE_APP_API_PATH_EDIT_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertPlane ({ state, dispatch }, plane) {
      console.log(plane)
      return axios.post(process.env.VUE_APP_API_PATH_ADD_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertPaybackCategory ({ state, dispatch }, payload) {
      return axios.post(process.env.VUE_APP_API_PATH_PAYBACK_CATEGORY_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(payload), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async validateFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async validateUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteFlight ({ state, dispatch }, flight) {
      return axios.get(process.env.VUE_APP_API_PATH_DELETE_FLIGHT + '?flightID=' + flight.flightID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_UPDATE_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async getFullUserInfo ({ state, dispatch }, id) {
      return axios.get(process.env.VUE_APP_API_PATH_GET_FULL_PILOT_INFO + '?userID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async getPlaneParts ({ state, dispatch, commit }, payload) {
      if (typeof payload.payload.customPayload.planeID === 'undefined') return
      await axios.get(process.env.VUE_APP_API_PATH_PLANE_PARTS_GET + '?planeID=' + payload.payload.customPayload.planeID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        .then(response => {
        //  console.log(response)
          commit('savePlanePartList', response.data.result)
        })
    },
    async getPlaneDocs ({ state, dispatch, commit }, payload) {
      if (typeof payload.payload.customPayload.planeID === 'undefined') return
      await axios.get(process.env.VUE_APP_API_PATH_PLANE_DOCS_GET + '?planeID=' + payload.payload.customPayload.planeID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        .then(response => {
        //  console.log(response)
          commit('savePlaneDocsList', response.data.result)
        })
    },
    async insertPlaneDocs ({ state, dispatch }, payload) {
      const formData = new FormData()
      for (var [key, value] of Object.entries(payload)) {
        formData.append(key, value)
      }
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_DOCS_INSERT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}`, 'Content-Type': 'multipart/form-data' } })
    },
    async getPlaneFlightReports ({ state, dispatch, commit }, payload) {
      if (typeof payload.payload.customPayload.planeID === 'undefined') return
      await axios.get(process.env.VUE_APP_API_PATH_PLANE_FLIGHT_REPORT_GET + '?planeID=' + payload.payload.customPayload.planeID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { timeout: 10000, headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        .then(response => {
        //  console.log(response)
          commit('savePlaneFlightReportList', response.data.result)
        })
    },
    async insertFlightReport ({ state, dispatch }, payload) {
      const formData = new FormData()
      for (var [key, value] of Object.entries(payload)) {
        formData.append(key, value)
      }
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_FLIGHT_REPORT_INSERT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}`, 'Content-Type': 'multipart/form-data' } })
    },
    async deleteFlightReport ({ state, commit }, payload) {
      if (state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_PLANE_FLIGHT_REPORT_DELETE + '?reportID=' + payload.reportID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async updateFlightReport ({ state, dispatch }, report) {
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_FLIGHT_REPORT_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(report), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async validatePlanePart ({ state, dispatch }, part) {
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_PART_VALIDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(part), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updatePlanePart ({ state, dispatch }, part) {
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_PART_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(part), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertPlanePart ({ state, dispatch }, part) {
      return axios.post(process.env.VUE_APP_API_PATH_PLANE_PART_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(part), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deletePlanePart ({ state, dispatch }, payload) {
      return axios.get(process.env.VUE_APP_API_PATH_PLANE_PART_DELETE + '?partID=' + payload.partID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_EDIT_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async addUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async changePassword ({ state, dispatch }, password) {
      return axios.post(process.env.VUE_APP_API_PATH_CHANGE_PASSWORD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(password), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateMailAttachedUsers ({ state, dispatch }, data) {
      data.users = data.users.join()
      data.usersWO = data.usersWO.join()
      return axios.post(process.env.VUE_APP_API_PATH_MAILINGLISTS_UPDATE_ATTACHED + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(data), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertPayback ({ state, dispatch }, payback) {
      const formData = new FormData()
      /*
      for (var i = 0; i < payback.files.length; i++) {
        formData.append('file[' + i + ']', payback.files[i])
      } */
      formData.append('file', payback.files)
      formData.append('creatorID', state.User.userID)
      formData.append('amount', payback.amount)
      formData.append('category', payback.category)
      formData.append('paybackType', payback.paybackType)
      formData.append('paybackTo', payback.paybackTo)
      formData.append('comment', payback.comment)
      formData.append('billingDate', payback.billingDate)
      formData.append('bic', payback.bic)
      formData.append('iban', payback.iban)
      formData.append('customerRef', payback.customerRef)
      formData.append('partnerName', payback.partnerName)
      formData.append('invoiceNo', payback.invoiceNo)
      formData.append('costCenter', payback.costCenter)
      //  console.log(formData)
      return axios.post(process.env.VUE_APP_API_PATH_USER_PAYBACK_CREATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Basic ${this.state.User.auth.authStr}`
        }
      })
    },
    async updatePayback ({ state, dispatch }, payback) {
      const formData = new FormData()
      /*
      for (var i = 0; i < payback.files.length; i++) {
        formData.append('file[' + i + ']', payback.files[i])
      } */
      formData.append('receiptID', payback.receiptID)
      formData.append('creatorID', state.User.userID)
      formData.append('amount', payback.amount)
      formData.append('approved', payback.approved)
      formData.append('category', payback.category)
      formData.append('paybackType', payback.paybackType)
      formData.append('paybackTo', payback.paybackTo)
      formData.append('comment', payback.comment)
      formData.append('billingDate', payback.billingDate)
      formData.append('bic', payback.bic)
      formData.append('iban', payback.iban)
      formData.append('customerRef', payback.customerRef)
      formData.append('partnerName', payback.partnerName)
      formData.append('invoiceNo', payback.invoiceNo)
      formData.append('costCenter', payback.costCenter)
      //  console.log(formData)
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_PAYBACK_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Basic ${this.state.User.auth.authStr}`
        }
      })
    },
    async addDownload ({ state, dispatch }, download) {
      const formData = new FormData()
      /*
      for (var i = 0; i < payback.files.length; i++) {
        formData.append('file[' + i + ']', payback.files[i])
      } */
      formData.append('file', download.files)
      formData.append('access', download.access)
      formData.append('comment', download.comment)
      formData.append('typ', download.typ) // Should be dynamic soon
      //  console.log(formData)
      return axios.post(process.env.VUE_APP_API_PATH_DOWNLOADS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Basic ${this.state.User.auth.authStr}`
        }
      })
    },
    async loadUserPaybackList ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_USER_PAYBACKLIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  console.log(response)
            commit('saveUserPaybackList', response.data.result)
          })
      }
    },
    async getPaybackRequests ({ commit, state }) {
      if (this.state.User.userID !== -1) {
        axios.get(process.env.VUE_APP_API_PATH_MANAGEMENT_PAYBACK_REQUESTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('savePaybackRequests', response.data.result)
          })
      }
    },
    async loginUser ({ commit, dispatch, state }, User) {
      commit('setUser', User)
      dispatch('initialiseAction')
    },
    async checkToken ({ state, dispatch, commit }, User) {
      if (User.userID !== -1) {
        const check = await axios.post(process.env.VUE_APP_API_PATH_VALIDATE_TOKEN + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify({ userName: User.auth.username, password: User.auth.password }))
        if (parseInt(check.data.result.userID) !== -1 && parseInt(check.data.result.userID) === User.userID) {
          // console.log('Token Check Successful')
        } else {
          commit('logout')
        }
      }
    },
    async logoutBackend ({ state, commit }, User) {
      axios.post(process.env.VUE_APP_API_PATH_LOGOUT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, [], { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      commit('logout')
    },
    async submitPaybackCheck ({ state, dispatch }, Check) {
      return axios.post(process.env.VUE_APP_API_PATH_PAYBACK_APPROVAL + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(Check), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadStatistics ({ state, commit }, Year) {
      var statisticsArray = []
      if (this.state.User.userID !== -1) {
        axios.get(process.env.VUE_APP_API_PATH_STATISTICS_PLANESTAT + '?y=' + Year, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
          statisticsArray.PlaneStat = response.data.result
        })
        /*
        axios.get(process.env.VUE_APP_API_PATH_STATISTICS_LAUNCHMETHOD + '?y=' + Year, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
          statisticsArray.LaunchMethods = response.data.result
        })
        */
        /* PLANE STATISTICS */
        await axios.get(process.env.VUE_APP_API_PATH_STATISTICS_PLANEFLIGHTTIMES + '?y=' + Year + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
          statisticsArray.PlaneFlightTimes = response.data
        })
        await axios.get(process.env.VUE_APP_API_PATH_STATISTICS_PLANELONGESTFLIGHTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
          statisticsArray.PlaneLongestFlights = response.data
        })
        commit('saveStatistics', statisticsArray)
      }
    },
    async loadFlightsPaidByUser ({ state }, id) {
      if (id === undefined) id = state.User.userID
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_USER_FLIGHTS_TO_PAY + '?userID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            return response.data.result
          })
      } else {
        return []
      }
    },
    async loadAllTransactions ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_TRANSACTIONS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAllUserTransactions', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertTransaction ({ state, dispatch }, transaction) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_TRANSACTIONS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(transaction), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateTransaction ({ state, dispatch }, transaction) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_TRANSACTIONS_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(transaction), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteTransaction ({ state, dispatch }, transaction) {
      return axios.get(process.env.VUE_APP_API_PATH_ADMIN_TRANSACTIONS_DELETE + '?transactionID=' + transaction.transactionID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllContracts ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAllContracts', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertContract ({ state, dispatch }, contract) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(contract), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateContract ({ state, dispatch }, contract) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(contract), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteContract ({ state, dispatch }, contract) {
      return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DELETE + '?contractID=' + contract.contractID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllContractDocuments ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DOCUMENTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAlLContractDocuments', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertContractDocument ({ state, dispatch }, document) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DOCUMENT_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(document), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateContractDocument ({ state, dispatch }, document) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DOCUMENT_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(document), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteContractDocument ({ state, dispatch }, document) {
      return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DOCUMENT_DELETE + '?contractDocID=' + document.contractDocID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllContractPayments ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_PAYMENTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAllContractPayments', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertContractPayment ({ state, dispatch }, payment) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_PAYMENT_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(payment), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateContractPayment ({ state, dispatch }, payment) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_PAYMENT_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(payment), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteContractPayment ({ state, dispatch }, payment) {
      return axios.get(process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_PAYMENT_DELETE + '?contractDocID=' + payment.contractPaymentID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllCoupons ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_COUPON_GET_ALL + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAllCoupons', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertCoupon ({ state, dispatch }, Coupon) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_COUPON_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(Coupon), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateCoupon ({ state, dispatch }, Coupon) {
      return axios.post(process.env.VUE_APP_API_PATH_ADMIN_COUPON_EDIT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(Coupon), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteCoupon ({ state, dispatch }, Coupon) {
      return axios.get(process.env.VUE_APP_API_PATH_ADMIN_COUPON_DELETE + '?couponID=' + Coupon.couponID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async costCenter ({ state, dispatch }, costCenter) {
      return axios.post(process.env.VUE_APP_API_PATH_COST_CENTERS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(costCenter), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllCostCenterBalance ({ state, commit }, payload) {
      console.log(payload)
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_COST_CENTERS_BALANCE_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (payload.payload != null || payload.payload !== undefined ? '&' + qs.stringify(payload.payload) : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAllCostCenterBalance', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadFlightsByCostCenter ({ state, dispatch, commit }, payload) {
      console.log(payload)
      if (typeof payload.payload === 'undefined') return
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_COST_CENTERS_FLIGHTS_GET + '?costCenterID=' + payload.payload.customPayload + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (payload.payload != null || payload.payload !== undefined ? '&' + qs.stringify(payload.payload) : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveFlightsByCostCenter', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadPaybackByCostCenter ({ state, dispatch, commit }, payload) {
      console.log(payload)
      if (typeof payload.payload === 'undefined') return
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_COST_CENTERS_PAYBACK_GET + '?costCenterID=' + payload.payload.customPayload + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (payload.payload != null || payload.payload !== undefined ? '&' + qs.stringify(payload.payload) : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('savePaybackByCostCenter', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadTransactionsByCostCenter ({ state, dispatch, commit }, payload) {
      if (typeof payload.payload === 'undefined') return
      console.log(payload)
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_COST_CENTERS_TRANSACTIONS_GET + '?costCenterID=' + payload.payload.customPayload + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (payload.payload != null || payload.payload !== undefined ? '&' + qs.stringify(payload.payload) : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveTransactionsByCostCenter', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadUserLicenses ({ state, commit }, customUserID) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_USER_LICENSE_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (customUserID !== null ? '&userID=' + customUserID : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async loadLicenseTypes ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_LICENSE_TYPES_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveLicenseTypes', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertUserLicense ({ state, dispatch }, license) {
      return axios.post(process.env.VUE_APP_API_PATH_USER_LICENSE_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(license), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateUserLicense ({ state, dispatch }, license) {
      return axios.post(process.env.VUE_APP_API_PATH_USER_LICENSE_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(license), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteUserLicense ({ state, dispatch }, license) {
      return axios.get(process.env.VUE_APP_API_PATH_USER_LICENSE_DELETE + '?uLicenseID=' + license.uLicenseID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllUserLicense ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_LICENSE_GET_ALL_USER + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async loadUserLicenseRights ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_USER_GET_license_RIGHTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveUserLicenseRights', response.data.result)
          })
      } else {
        return []
      }
    },
    async getLicenseInfo ({ state, dispatch }, id) {
      return axios.get(process.env.VUE_APP_API_PATH_USER_LICENSE_INFO + '?licenseID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadUserFlightStatistic ({ state, commit }, data) {
      if (this.state.User.userID !== -1) {
        return axios
          .get(process.env.VUE_APP_API_PATH_USER_GET_FLIGHT_STATISTIC + '?dStart=' + data.dStart + '&dEnd=' + data.dEnd + (data.customUserID !== null ? '&userID=' + data.customUserID : '') + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async loadAllTasks ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TASKS_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async assignTask ({ state, commit }, task) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TASK_ASSIGN + '?taskID=' + task.taskID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async doneTask ({ state, commit }, task) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TASK_DONE + '?taskID=' + task.taskID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async approveTask ({ state, commit }, task) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TASK_APPROVE + '?taskID=' + task.taskID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async deleteTask ({ state, commit }, task) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TASK_DELETE + '?taskID=' + task.taskID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async insertTask ({ state, dispatch }, task) {
      return axios.post(process.env.VUE_APP_API_PATH_TASK_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(task), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateTask ({ state, dispatch }, task) {
      return axios.post(process.env.VUE_APP_API_PATH_TASK_EDIT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(task), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertEvent ({ state, dispatch }, event) {
      return axios.post(process.env.VUE_APP_API_PATH_EVENT_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(event), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateEvent ({ state, dispatch }, event) {
      return axios.post(process.env.VUE_APP_API_PATH_EVENT_EDIT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(event), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAllEvents ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_EVENT_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async deleteEvent ({ state, commit }, event) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_EVENT_DELETE + '?eventID=' + event.eventID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async recalcFlightCost ({ state, commit, dispatch }) {
      if (this.state.User.userID !== -1) {
        axios.get(process.env.VUE_APP_API_PATH_RECALCULATE_FLIGHT_COSTS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      }
    },
    async loadUserDocuments ({ state, commit }, payload) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_USER_DOCUMENTS_GET + payload.payload.customPayload + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveUserDocuments', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadUserDocumentsCategories ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_DOCUMENTS_CATEGORIES_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveUserDocumentCategories', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertUserDocuments ({ state, dispatch }, payload) {
      console.log(payload)
      const formData = new FormData()
      for (var [key, value] of Object.entries(payload)) {
        formData.append(key, value)
      }
      return axios.post(process.env.VUE_APP_API_PATH_USER_DOCMENTS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, formData, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}`, 'Content-Type': 'multipart/form-data' } })
    },
    async updateUserDocuments ({ state, dispatch }, document) {
      return axios.post(process.env.VUE_APP_API_PATH_USER_DOCMENTS_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(document), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteUserDocuments ({ state, dispatch }, document) {
      return axios.get(process.env.VUE_APP_API_PATH_USER_DOCMENTS_DELETE + '?uDocID=' + document.uDocID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async sendInvoiceToMultipleUsers ({ state, dispatch }, data) {
      return axios.post(process.env.VUE_APP_API_PATH_GENERATE_INVOICE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(data), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async loadAdminPayback ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_ADMIN_PAYBACK + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
          commit('saveAdminPaybackData', response.data.result)
        })
      } else {
        return []
      }
    },
    async loadAdminInvoice ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_INVOICE_GET_ALL + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAdminInvoice', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadAdminWithdraws ({ state, commit }, date) {
      if (this.state.User.userID !== -1) {
        if (date.from === '') {
          date.from = null
        }
        if (date.until === '') {
          date.until = null
        }
        return axios.get(process.env.VUE_APP_API_PATH_ACCOUNTING_WITHDRAW_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + (date.from !== null ? '&dStart=' + date.from : '') + (date.until !== null ? '&dEnd=' + date.until : ''), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return []
      }
    },
    async updateWithdraw ({ state, dispatch }, withdraw) {
      return axios.post(process.env.VUE_APP_API_PATH_ACCOUNTING_WITHDRAW_UPDATE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(withdraw), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteAdminInvoice ({ state, dispatch }, invoice) {
      return axios.get(process.env.VUE_APP_API_PATH_INVOICE_DELETE + '?transferID=' + invoice.transferID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async generateTransactionFromInvoice ({ state, dispatch }, invoiceList) {
      var transferIDList = ''
      if (invoiceList.length !== undefined && invoiceList.length > 0) {
        invoiceList.forEach(item => {
          transferIDList = transferIDList + item.transferID + ';'
        })
      } else {
        transferIDList = invoiceList.transferID
      }
      if (confirm('Bist du sicher, dass du die Rechnung(en) in die Transactions überführen und ausgleichen willst?')) {
        return axios.get(process.env.VUE_APP_API_PATH_INVOICE_GENERATE_TRANSACTION + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + '&transferID=' + transferIDList, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      } else {
        return false
      }
    },
    async loadAllTags ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TAGS_GET_ALL + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveAdminTags', response.data.result)
          })
      } else {
        return []
      }
    },
    async loadUserTags ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        return axios.get(process.env.VUE_APP_API_PATH_TAGS_USER_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            commit('saveUserTags', response.data.result)
          })
      } else {
        return []
      }
    },
    async insertTag ({ state, dispatch }, Tag) {
      return axios.get(process.env.VUE_APP_API_PATH_TAGS_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + '&' + qs.stringify(Tag), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateTag ({ state, dispatch }, Tag) {
      console.log(qs.stringify(Tag))
      return axios.get(process.env.VUE_APP_API_PATH_TAGS_EDIT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + '&' + qs.stringify(Tag), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteTag ({ state, dispatch }, Tag) {
      return axios.get(process.env.VUE_APP_API_PATH_TAGS_DELETE + '?tagID=' + Tag.tagID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async exportVereinsflieger ({ state, dispatch }, date) {
      return axios.get(process.env.VUE_APP_API_PATH_EXPORT_TO_VEREINSFLIEGER + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + '&' + qs.stringify(date), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async recalculateFlightCost ({ state, dispatch }, flight) {
      return axios.get(process.env.VUE_APP_API_PATH_FLIGHT_RECALC_COST + '?flightID=' + flight.flightID + '&recalc=1&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    }
  },
  getters: {
    loggedIn: state => {
      if (state.User.userID !== -1) {
        return true
      }
      return false
    }
  },
  modules: {
  }
})
