<template>
  <div>
    <v-dialog v-model="dialog" v-if="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span><v-spacer></v-spacer>{{
              editedIndex != -1
                ? "#" + editedItem.flightID
                : ""
          }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-autocomplete v-model="editedItem.planeID" :items="$store.state.PlaneList"
                      v-on:change="planeSelectEvent" item-text="callsign" clearable outlined disabled dense
                      item-value="planeID" label="Flugzeug"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field disabled v-model="editedItem.callsign" label="Alt. Kennz."></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field disabled v-model="editedItem.planeClub" label="Alt. Club"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete v-model="editedItem.flightType" :items="computedFlightTypeList" item-text="title"
                      item-value="typeID" dense outlined disabled label="Flugtyp"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete v-model="editedItem.pilotID" :items="computedPilotList" clearable dense disabled
                      outlined item-value="userID" item-text="fullname" label="Pilot">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field disabled v-model="editedItem.pilotName" label="Alt. Pilot"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete v-model="editedItem.passengerID" :items="computedCopilotList" disabled
                      item-value="userID" item-text="fullname" label="Begleiter" dense outlined clearable>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field disabled v-model="editedItem.passengerName" label="Alt. Begleiter"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete v-model="editedItem.paymentMethod" :items="$store.state.PaymentTypeList"
                      item-text="title" item-value="paymenttypeID" label="Zahlart" dense disabled
                      outlined></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="editedItem.paymentMethod === 5">
                    <v-autocomplete v-model="editedItem.payerID" :items="computedPilotListAll" outlined dense chips
                      small-chips disabled multiple v-on:change="updateDots()" item-value="userID" item-text="fullname"
                      label="Zahler">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="editedItem.paymentMethod === 5">
                    <vue-slider v-model="editedItem.payerSliderSplit" :process="computedPaysplitSliderProcess" v-if="
                      editedItem.paymentMethod === 5 &&
                      editedItem.payerID != null &&
                      editedItem.payerID.length > 1
                    " v-on:drag-end="sliderChange()" v-on:change="sliderChange()"
                      v-on:drag-start="sliderChange()" v-on:dragging="sliderChange()" disabled>
                      <template v-slot:process="{ start, end, style, index }">
                        <div class="vue-slider-process" :style="style">
                          <div :class="[
                            'merge-tooltip',
                            'vue-slider-dot-tooltip-inner',
                            'vue-slider-dot-tooltip-inner-top'
                          ]">
                            {{
                                editedItem.payerSliderSplit[index + 1] -
                                editedItem.payerSliderSplit[index + 0]
                            }}%
                            {{
                                $store.state.PilotList.filter(
                                  e =>
                                    e.userID === editedItem.payerID[index]
                                )[0].firstname
                            }}
                          </div>
                        </div>
                      </template>
                    </vue-slider>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="
                    editedItem.paymentMethod === 2 ||
                    editedItem.paymentMethod === 3 ||
                    editedItem.paymentMethod === 4
                  ">
                    <v-text-field v-model="editedItem.payerID" label="Gutschein-Code" disabled></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical class="hidden-sm-and-down"></v-divider>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete v-model="editedItem.launchMethod" :items="$store.state.LaunchmethodList"
                      item-text="title" item-value="launchID" label="Startart" dense disabled outlined></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="7">
                    <v-autocomplete v-model="editedItem.takeoffLocation" :items="computedAirportList"
                      :filter="filterAirportObject" clearable dense disabled outlined item-value="icao"
                      label="Startort">
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.icao }} {{ data.item.city }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.icao }} {{ data.item.city }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <v-checkbox v-model="editedItem.manualToTime" disabled color="red" hide-details>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="11" md="2">
                    <vue-timepicker :class="{ white: $vuetify.theme.dark }" format="HH:mm" manual-input auto-scroll
                      disabled dark v-model="editedItem.takeoffTimeSelector"></vue-timepicker>
                  </v-col>
                  <v-col cols="12" sm="12" md="7">
                    <v-autocomplete v-model="editedItem.landingLocation" :items="computedAirportList"
                      :filter="filterAirportObject" clearable dense disabled outlined item-value="icao"
                      label="Landeort">
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.icao }} {{ data.item.city }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.icao }} {{ data.item.city }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <v-checkbox v-model="editedItem.manualLandTime" disabled color="red" hide-details>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="11" md="2">
                    <vue-timepicker :class="{ white: $vuetify.theme.dark }" format="HH:mm" manual-input auto-scroll
                      disabled v-model="editedItem.landingTimeSelector"></vue-timepicker>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.engineStart" v-if="computedPlaneHasEngine" disabled
                      label="Motorzähler Start"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.engineStop" v-if="computedPlaneHasEngine" disabled
                      label="Motorzähler Stop"></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <v-checkbox v-model="
                      editedItem.editNumberOfLandingsOnTakeoffLocation
                    " color="red" disabled hide-details>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field v-model="editedItem.landingsTakeoffLocation" disabled type="number"
                      label="Landungen am Startort"></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <v-checkbox v-model="
                      editedItem.editNumberOfLandingsOnLandingLocation
                    " disabled color="red" hide-details>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field v-model="editedItem.landingsLandingLocation" disabled type="number"
                      label="Landungen am Landeort"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea outlined disabled name="FlightComment" label="Kommentare"
                      v-model="editedItem.comment"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" sm="12" v-if="
                    editedItem.launchMethod === 2 && editedIndex === -1
                  ">
                    <v-card>
                      <v-row>
                        <v-col cols="6" sm="6">
                          <v-row>
                            <v-col cols="4" sm="4">
                              <v-autocomplete v-model="editedItem.towflight.planeID" :items="computedTowplaneList"
                                item-text="callsign" clearable outlined disabled dense item-value="planeID"
                                label="Flugzeug"></v-autocomplete>
                            </v-col>
                            <v-col cols="4" sm="4">
                              <v-text-field disabled v-model="editedItem.towflight.callsign"
                                label="Alternative Callsign"></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                              <v-text-field disabled v-model="editedItem.towflight.planeClub"
                                label="Alternative Club"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-autocomplete v-model="editedItem.towflight.flightType" :items="computedFlightTypeList"
                                item-text="title" item-value="typeID" dense disabled outlined
                                label="Flugtyp"></v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-autocomplete v-model="editedItem.towflight.pilotID" :items="computedTowpilotList"
                                :filter="filterPilotObject" clearable dense disabled outlined item-value="userID"
                                label="Pilot">
                                <template slot="selection" slot-scope="data">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                                <template slot="item" slot-scope="data">
                                  <!-- HTML that describe how select should render items when the select is open -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-text-field disabled v-model="editedItem.towflight.pilotName"
                                label="Alternative Pilot Name"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-autocomplete v-model="editedItem.towflight.passengerID" :items="computedTowcopilotList"
                                disabled :filter="filterPilotObject" item-value="userID" label="Begleiter" dense
                                outlined clearable>
                                <template slot="selection" slot-scope="data">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                                <template slot="item" slot-scope="data">
                                  <!-- HTML that describe how select should render items when the select is open -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-text-field disabled v-model="
                                editedItem.towflight.passengerName
                              " label="Alternativer Begleiter Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-autocomplete v-model="
                                editedItem.towflight.paymentMethod
                              " :items="$store.state.PaymentTypeList" item-text="title"
                                item-value="paymenttypeID" label="Zahlart" dense disabled outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="
                              editedItem.towflight.paymentMethod === 5
                            ">
                              <v-autocomplete v-model="editedItem.towflight.payerID" :items="$store.state.PilotList"
                                chips multiple dense disabled clearable outlined v-on:change="updateTowDots()"
                                item-value="userID" label="Payer">
                                <template slot="selection" slot-scope="data">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                                <template slot="item" slot-scope="data">
                                  <!-- HTML that describe how select should render items when the select is open -->
                                  {{ data.item.firstname }}
                                  {{ data.item.lastname }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="
                              editedItem.towflight.paymentMethod === 5
                            ">
                              <vue-slider v-model="
                                editedItem.towflight.payerSliderSplit
                              " :process="
  computedTowPaysplitSliderProcess
" v-if="
  editedItem.towflight.paymentMethod ===
  5 &&
  editedItem.towflight.payerID !=
  null &&
  editedItem.towflight.payerID.length >
  1
" v-on:drag-end="towSliderChange()" v-on:change="towSliderChange()"
                                v-on:drag-start="towSliderChange()" v-on:dragging="towSliderChange()" disabled>
                                <template v-slot:process="{
                                  start,
                                  end,
                                  style,
                                  index
                                }">
                                  <div class="vue-slider-process" :style="style">
                                    <div :class="[
                                      'merge-tooltip',
                                      'vue-slider-dot-tooltip-inner',
                                      'vue-slider-dot-tooltip-inner-top'
                                    ]">
                                      {{
                                          editedItem.towflight
                                            .payerSliderSplit[index + 1] -
                                          editedItem.towflight
                                            .payerSliderSplit[index + 0]
                                      }}%
                                      {{
                                          $store.state.PilotList.filter(
                                            e =>
                                              e.userID ===
                                              editedItem.towflight.payerID[
                                              index
                                              ]
                                          )[0].firstname
                                      }}
                                    </div>
                                  </div>
                                </template>
                              </vue-slider>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="
                              editedItem.towflight.paymentMethod ===
                              3 ||
                              editedItem.towflight.paymentMethod === 4
                            ">
                              <v-text-field v-model="editedItem.towflight.payerID" label="Gutschein-Code"
                                disabled></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6" sm="6">
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-autocomplete v-model="
                                editedItem.towflight.launchMethod
                              " :items="$store.state.LaunchmethodList" disabled item-text="title"
                                item-value="launchID" label="Startart" dense outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-autocomplete v-model="
                                editedItem.towflight.takeoffLocation
                              " :items="computedAirportList" :filter="filterAirportObject" clearable disabled
                                dense outlined item-value="icao" label="Startort">
                                <template slot="selection" slot-scope="data">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ data.item.icao }}
                                  {{ data.item.city }}
                                </template>
                                <template slot="item" slot-scope="data">
                                  <!-- HTML that describe how select should render items when the select is open -->
                                  {{ data.item.icao }}
                                  {{ data.item.city }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="1" sm="1">
                              <v-checkbox v-model="
                                editedItem.towflight.manualToTime
                              " color="red" disabled hide-details>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" sm="5">
                              <vue-timepicker :class="{ white: $vuetify.theme.dark }" format="HH:mm" manual-input
                                disabled auto-scroll v-model="
                                  editedItem.towflight.takeoffTimeSelector
                                "></vue-timepicker>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-autocomplete v-model="
                                editedItem.towflight.landingLocation
                              " :items="computedAirportList" :filter="filterAirportObject" clearable dense
                                disabled outlined item-value="icao" label="Landeort">
                                <template slot="selection" slot-scope="data">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ data.item.icao }}
                                  {{ data.item.city }}
                                </template>
                                <template slot="item" slot-scope="data">
                                  <!-- HTML that describe how select should render items when the select is open -->
                                  {{ data.item.icao }}
                                  {{ data.item.city }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="1" sm="1">
                              <v-checkbox v-model="
                                editedItem.towflight.manualLandTime
                              " color="red" disabled hide-details>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" sm="5">
                              <vue-timepicker :class="{ white: $vuetify.theme.dark }" format="HH:mm" manual-input
                                auto-scroll disabled v-model="
                                  editedItem.towflight.landingTimeSelector
                                "></vue-timepicker>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field v-model="editedItem.towflight.engineStart" v-if="computedPlaneHasEngine"
                                disabled label="Engine Start"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field v-model="editedItem.towflight.engineStop" v-if="computedPlaneHasEngine"
                                disabled label="Engine Stop"></v-text-field>
                            </v-col>
                            <v-col cols="1" sm="1">
                              <v-checkbox v-model="
                                editedItem.editNumberOfLandingsOnTakeoffLocation
                              " disabled color="red" hide-details>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" sm="6">
                              <v-text-field v-model="
                                editedItem.towflight
                                  .landingsTakeoffLocation
                              " disabled type="number" label="Landungen am Startort"></v-text-field>
                            </v-col>
                            <v-col cols="1" sm="1">
                              <v-checkbox v-model="
                                editedItem.towflight
                                  .editNumberOfLandingsOnLandingLocation
                              " color="red" hide-details>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" sm="6">
                              <v-text-field v-model="
                                editedItem.towflight
                                  .landingsLandingLocation
                              " disabled type="number" label="Landungen am Landeort"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-textarea outlined name="TowflightComment" label="Kommentar"
                                v-model="editedItem.towflight.comment"></v-textarea>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="showPrice ? headersAdvanced : headers" :items="computedFlightList" class="elevation-1" :dense="denseExternal"
      id="flighttable" no-data-text="Keine Flüge vorhanden..." hide-default-footer sort-by="flightID" :sort-desc="true" mobile-breakpoint="0"
      :items-per-page.sync="computedFlightList.length" :search="searchExternal" @contextmenu:row="rightClickHandler" @dblclick:row="rightClickHandler">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ computedFlightList.length }} {{ titleExternal != '' ? titleExternal :
              'Flüge'
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-switch v-if="flightsExternal.length === 0" v-model="showFlightsWithMe"
            :label="showFlightsWithMe ? `PIC und Beisitzer flüge angezeigt` : `Nur PIC flüge angezeigt`"
            color="orange"></v-switch>
          <v-spacer></v-spacer>
          <v-switch v-if="flightsExternal.length === 0" v-model="showAllFlights" label="Alle Flüge die ich Bezahle"
            color="red"></v-switch>
          <v-spacer></v-spacer>
          <v-text-field type="number" min="2020" steps="1" v-if="flightsExternal.length === 0" v-model="showFlightsYear" label="Anzeigejahr" style="transform: translateY(6px);"
            color="red"></v-text-field>
            <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical v-if="showPrice"></v-divider>
          <v-toolbar-title v-if="showPrice">Summe Flugkosten: <strong style="color: red">{{ computedAccumulatedPrice }}€ </strong></v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.date="{ item }"><span>{{ formatDate(item.date) }}</span></template>
      <template v-slot:item.flightType="{ item }"><span>{{
          item.flightType != null ? $store.state.FlightTypeList.filter(e => e.typeID == item.flightType)[0].title :
            "fail"
      }}</span></template>
      <template v-slot:item.launchtype="{ item }"><span>{{
          item.launchMethod != null ? $store.state.LaunchmethodList.filter(e => e.launchID ==
            item.launchMethod)[0].title : "fail"
      }}</span></template>
      <template v-slot:item.toTime="{ item }"><span v-if="item.takeoffTime != null">{{ TimestampToTime(item.takeoffTime)
      }} UTC</span>
        <span v-else-if="item.flightType === 9"> Kommt</span>
        <span v-else> ERROR</span></template>
      <template v-slot:item.landTime="{ item }"><span v-if="item.landingTime != null">{{
          TimestampToTime(item.landingTime)
      }} UTC</span> <span v-else-if="item.flightType === 10"> Geht</span>
        <span v-else>
          ERROR</span></template>
      <template v-slot:item.duration="{ item }"><span v-if="item.takeoffTime !== null && item.landingTime !== null">{{
          calculateTimeDifference(item.takeoffTime, item.landingTime)
      }}</span><span v-else-if="
  item.takeoffTime != null &&
  item.landingTime == null &&
  item.flightType !== 10
">{{ calculateTimeDifference(item.takeoffTime, moment().utc().format('YYYY-MM-DD HH:mm:ss')) }}</span><span
          v-else>-----</span></template>
      <template v-slot:item.payment="{ item }">
        <span>{{ $store.state.PaymentTypeList.filter(e => e.paymenttypeID === item.paymentMethod)[0].title }}</span>
      </template>
      <template v-slot:item.totalCost="{ item }">
        <v-tooltip left content-class="custom-price-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ showOnlyMyPricePart ? parseFloat(item.myPart).toFixed(2) : priceFloat(item.totalCost).toFixed(2) }}€</span>
          </template>
          <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Startkosten</td>
          <td>{{ parseFloat(item.launchCost).toFixed(2) }}€</td>
        </tr>
        <tr>
          <td>Landekosten</td>
          <td>{{ parseFloat(item.landingCost).toFixed(2) }}€</td>
        </tr>
        <tr>
          <td>Flugkosten</td>
          <td>{{ parseFloat(item.flightTimeCost).toFixed(2) }}€</td>
        </tr>
        <tr>
          <td>Summe</td>
          <td>{{ parseFloat(item.totalCost).toFixed(2) }}€</td>
        </tr>
        <tr>
          <td><strong>Mein Anteil</strong></td>
          <td>{{ parseFloat(item.myPart).toFixed(2) }}€</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- RIGHT CLICK MENU -->
    <v-menu v-model="TableMenu.showMenu" :position-x="TableMenu.x" :position-y="TableMenu.y" absolute offset-y>
      <v-list dense>
        <v-list-item dense @click="editLastSelectedItem">
          <v-list-item-icon>
            <v-icon>fa-info</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Information</v-list-item-title>
        </v-list-item>
        <v-list-item dense @click="recalcFlightCost" v-if="$store.state.UserInfo.adm === 1">
          <v-list-item-icon>
            <v-icon>fa-sync</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Flug neu berechnen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- RIGHT CLICK MENU  END -->
  </div>
</template>
<style >
.merge-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -15px);
}
.custom-price-tooltip {
  padding: 0px !important;
  opacity: 1 !important;
  border: 1px solid black !important;
}
</style>
<script>
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  data: () => ({
    dialog: false,
    headers: [
      { text: 'Datum', value: 'date' },
      { text: 'Callsign', align: 'start', value: 'callsign' },
      { text: 'Pilot', value: 'pilotname' },
      { text: 'Begleiter', value: 'copilotname' },
      { text: 'Flugtyp', value: 'flightType' },
      { text: 'Startart', value: 'launchtype' },
      { text: 'Startort', value: 'takeoffLocation' },
      { text: 'Start Zeit', value: 'toTime' },
      { text: 'Lande Zeit', value: 'landTime' },
      { text: 'Landeort', value: 'landingLocation' },
      { text: 'Dauer', value: 'duration' },
      { text: 'ID', value: 'flightID' },
      { text: 'Zahlung', value: 'payment' }
    ],
    headersAdvanced: [
      { text: 'Datum', value: 'date' },
      { text: 'Callsign', align: 'start', value: 'callsign' },
      { text: 'Pilot', value: 'pilotname' },
      { text: 'Begleiter', value: 'copilotname' },
      { text: 'Flugtyp', value: 'flightType' },
      { text: 'Startart', value: 'launchtype' },
      { text: 'Startort', value: 'takeoffLocation' },
      { text: 'Start Zeit', value: 'toTime' },
      { text: 'Lande Zeit', value: 'landTime' },
      { text: 'Landeort', value: 'landingLocation' },
      { text: 'Dauer', value: 'duration' },
      { text: 'Zahlung', value: 'payment' },
      { text: 'ID', value: 'flightID' },
      { text: 'Preis', value: 'totalCost' }
    ],
    TableMenu: {
      showMenu: false,
      x: null,
      y: null
    },
    editedItem: {
      planeID: null,
      planeClub: null,
      callsign: null,
      paymentMethod: 1,
      launchMethod: null,
      pilotID: null,
      passengerID: null,
      pilotName: null,
      passengerName: null,
      flightType: 3,
      towFlightID: null,
      isTowplane: null,
      takeoffLocation: null,
      landingLocation: null,
      takeoffTime: null,
      takeoffTimeSelector: {
        HH: '',
        mm: ''
      },
      landingTime: null,
      landingTimeSelector: {
        HH: '',
        mm: ''
      },
      creatorID: null,
      creationTime: null,
      payerID: null,
      payerSliderSplit: [0, 100],
      paySplit: null,
      engineStart: null,
      engineStop: null,
      manualToTime: false,
      manualLandTime: false,
      comment: '',
      landingsTakeoffLocation: 0,
      landingsLandingLocation: 0,
      editNumberOfLandingsOnLandingLocation: false,
      editNumberOfLandingsOnTakeoffLocation: false,
      towflight: {
        planeID: null,
        planeClub: null,
        callsign: null,
        paymentMethod: 1,
        launchMethod: 3,
        pilotID: null,
        passengerID: null,
        pilotName: null,
        passengerName: null,
        flightType: 3,
        towFlightID: null,
        isTowplane: 1,
        takeoffLocation: null,
        landingLocation: null,
        takeoffTime: null,
        takeoffTimeSelector: {
          HH: '',
          mm: ''
        },
        landingTime: null,
        landingTimeSelector: {
          HH: '',
          mm: ''
        },
        creatorID: null,
        creationTime: null,
        payerID: null,
        payerSliderSplit: [0, 100],
        paySplit: null,
        engineStart: null,
        engineStop: null,
        manualToTime: false,
        manualLandTime: false,
        comment: '',
        landingsTakeoffLocation: 0,
        landingsLandingLocation: 0,
        editNumberOfLandingsOnLandingLocation: false,
        editNumberOfLandingsOnTakeoffLocation: false
      }
    },
    defaultItem: {
      planeID: null,
      planeClub: null,
      callsign: null,
      paymentMethod: 1,
      launchMethod: null,
      pilotID: null,
      passengerID: null,
      pilotName: null,
      passengerName: null,
      flightType: 3,
      towFlightID: null,
      isTowplane: null,
      takeoffLocation: null,
      landingLocation: null,
      takeoffTime: null,
      takeoffTimeSelector: {
        HH: '',
        mm: ''
      },
      landingTime: null,
      landingTimeSelector: {
        HH: '',
        mm: ''
      },
      creatorID: null,
      creationTime: null,
      payerID: null,
      payerSliderSplit: [0, 100],
      paySplit: null,
      engineStart: null,
      engineStop: null,
      manualToTime: false,
      manualLandTime: false,
      comment: '',
      landingsTakeoffLocation: 0,
      landingsLandingLocation: 0,
      editNumberOfLandingsOnLandingLocation: false,
      editNumberOfLandingsOnTakeoffLocation: false,
      towflight: {
        planeID: null,
        planeClub: null,
        callsign: null,
        paymentMethod: 1,
        launchMethod: 3,
        pilotID: null,
        passengerID: null,
        pilotName: null,
        passengerName: null,
        flightType: 3,
        towFlightID: null,
        isTowplane: 1,
        takeoffLocation: null,
        landingLocation: null,
        takeoffTime: null,
        takeoffTimeSelector: {
          HH: '',
          mm: ''
        },
        landingTime: null,
        landingTimeSelector: {
          HH: '',
          mm: ''
        },
        creatorID: null,
        creationTime: null,
        payerID: null,
        payerSliderSplit: [0, 100],
        paySplit: null,
        engineStart: null,
        engineStop: null,
        manualToTime: false,
        manualLandTime: false,
        comment: '',
        landingsTakeoffLocation: 0,
        landingsLandingLocation: 0,
        editNumberOfLandingsOnLandingLocation: false,
        editNumberOfLandingsOnTakeoffLocation: false
      }
    },
    showFlightsWithMe: false,
    showAllFlights: false,
    showFlightsYear: null
  }),
  components: {
    VueSlider,
    VueTimepicker
  },
  props: {
    flightsExternal: {
      type: Array,
      default () {
        return []
      },
      required: false
    },
    searchExternal: {
      type: String,
      default () {
        return ''
      },
      required: false
    },
    titleExternal: {
      type: String,
      default () {
        return ''
      },
      required: false
    },
    denseExternal: {
      type: Boolean,
      default () {
        return false
      },
      required: false
    },
    showPrice: {
      type: Boolean,
      default () {
        return false
      },
      required: false
    },
    showOnlyMyPricePart: {
      type: Boolean,
      default () {
        return false
      },
      required: false
    }
  },
  computed: {
    computedFlightList () {
      if (this.$store.state.PilotList.length === 0 || this.$store.state.PlaneList.length === 0) return []
      if (this.flightsExternal.length > 0) {
        const flights = this.flightsExternal

        for (var i = 0; i < flights.length; i++) {
          flights[i].pilotname = flights[i].pilotID != null ? this.$store.state.PilotList.filter(e => e.userID === flights[i].pilotID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === flights[i].pilotID)[0].lastname : flights[i].pilotName
          flights[i].copilotname = flights[i].passengerID != null ? this.$store.state.PilotList.filter(e => e.userID === flights[i].passengerID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === flights[i].passengerID)[0].lastname : flights[i].passengerName
          flights[i].callsign = flights[i].planeID !== null ? this.$store.state.PlaneList.filter(e => e.planeID === flights[i].planeID)[0].callsign : flights[i].callsign
          flights[i].date = this.getDateFromTimestamp(flights[i].takeoffTime)
        }
        return flights
      }
      const allFlightsArray = this.flightList

      for (var x = 0; x < allFlightsArray.length; x++) {
        allFlightsArray[x].pilotname = allFlightsArray[x].pilotID != null ? this.$store.state.PilotList.filter(e => e.userID === allFlightsArray[x].pilotID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === allFlightsArray[x].pilotID)[0].lastname : allFlightsArray[x].pilotName
        allFlightsArray[x].copilotname = allFlightsArray[x].passengerID != null ? this.$store.state.PilotList.filter(e => e.userID === allFlightsArray[x].passengerID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === allFlightsArray[x].passengerID)[0].lastname : allFlightsArray[x].passengerName
        allFlightsArray[x].callsign = allFlightsArray[x].planeID !== null ? this.$store.state.PlaneList.filter(e => e.planeID === allFlightsArray[x].planeID)[0].callsign : allFlightsArray[x].callsign
        allFlightsArray[x].date = this.getDateFromTimestamp(allFlightsArray[x].takeoffTime)
      }

      const userID = this.$store.state.User.userID
      if (this.showAllFlights) {
        if (this.showFlightsWithMe) {
          return allFlightsArray
        }
        return allFlightsArray.filter(e => e.payerID === userID)
      }

      if (this.showFlightsWithMe) {
        return allFlightsArray.filter(e => e.pilotID === userID || e.passengerID === userID)
      }

      return allFlightsArray.filter(e => e.pilotID === userID)
    },
    flightList () {
      if (this.flightsExternal.length > 0) {
        return this.flightsExternal
      }
      return this.$store.state.OnlineFlightList
    },
    computedNumberOfFlights () {
      if (this.flightsExternal.length > 0) {
        return this.flightsExternal.length
      }
      return this.$store.state.OnlineFlightList.length
    },
    computedAirportList () {
      var airports = []
      var AirportList = this.$store.state.AirportList

      for (var i = 0; i < AirportList.length; i++) {
        airports.push({
          icao: AirportList[i].ICAO,
          city: AirportList[i].cityName
        })
      }
      return airports
    },
    formTitle () {
      return 'Flug Informationen'
    },
    computedFlightTypeList () {
      if (this.editedItem.planeID !== null) {
        var seatsInPlane = this.$store.state.PlaneList.filter(
          e => e.planeID === this.editedItem.planeID
        )[0].seats
        return this.$store.state.FlightTypeList.filter(
          e => seatsInPlane >= e.minNumberOfPilots
        )
      } else {
        return this.$store.state.FlightTypeList
      }
    },
    computedPilotList () {
      if (this.editedItem.passengerID != null) {
        return this.$store.state.PilotList.filter(
          e =>
            e.userID !== this.editedItem.passengerID &&
            e.userID !== this.editedItem.towflight.pilotID &&
            e.userID !== this.editedItem.towflight.passengerID
        )
      } else {
        return this.$store.state.PilotList
      }
    },
    computedPilotListAll () {
      return this.$store.state.PilotList
    },
    computedCopilotList () {
      if (this.editedItem.pilotID != null) {
        return this.$store.state.PilotList.filter(
          e =>
            e.userID !== this.editedItem.pilotID &&
            e.userID !== this.editedItem.towflight.pilotID &&
            e.userID !== this.editedItem.towflight.passengerID
        )
      } else {
        return this.$store.state.PilotList
      }
    },
    maxPilotsReached () {
      if (this.editedItem.planeID != null) {
        if (
          this.$store.state.PlaneList.filter(
            e => e.planeID === this.editedItem.planeID
          )[0].seats < 2
        ) {
          return true
        } else {
          if (this.editedItem.flightType != null) {
            if (
              this.$store.state.FlightTypeList.filter(
                e => e.typeID === this.editedItem.flightType
              )[0].maxNumberOfPilots < 2
            ) {
              return true
            }
            return false
          }
          return false
        }
      }
      return false
    },
    maxTowPilotsReached () {
      if (this.editedItem.towflight.planeID != null) {
        if (
          this.$store.state.PlaneList.filter(
            e => e.planeID === this.editedItem.towflight.planeID
          )[0].seats < 2
        ) {
          return true
        } else {
          if (this.editedItem.towflight.flightType != null) {
            if (
              this.$store.state.FlightTypeList.filter(
                e => e.typeID === this.editedItem.towflight.flightType
              )[0].maxNumberOfPilots < 2
            ) {
              return true
            }
            return false
          }
          return false
        }
      }
      return false
    },
    computedPlaneHasEngine () {
      if (this.editedItem.planeID != null) {
        var callsign = this.$store.state.PlaneList.filter(
          e => e.planeID === this.editedItem.planeID
        )[0].callsign
        if (this.isNumber(callsign[2])) {
          return false
        }
        return true
      } else {
        return false
      }
    },
    computedTowPaysplitSliderProcess () {
      var array = []
      for (var i = 0; i < this.editedItem.towflight.payerID.length; i++) {
        var array1 = []
        array1.push(this.editedItem.towflight.payerSliderSplit[i])
        array1.push(this.editedItem.towflight.payerSliderSplit[i + 1])
        array.push(array1)
      }
      return val => array
    },
    computedPaysplitSliderProcess () {
      var array = []
      for (var i = 0; i < this.editedItem.payerID.length; i++) {
        var array1 = []
        array1.push(this.editedItem.payerSliderSplit[i])
        array1.push(this.editedItem.payerSliderSplit[i + 1])
        array.push(array1)
      }
      return val => array
    },
    computedTowpilotList () {
      return this.$store.state.PilotList.filter(
        e =>
          e.userID !== this.editedItem.pilotID &&
          e.userID !== this.editedItem.passengerID &&
          e.userID !== this.editedItem.towflight.passengerID
      )
    },
    computedTowcopilotList () {
      return this.$store.state.PilotList.filter(
        e =>
          e.userID !== this.editedItem.pilotID &&
          e.userID !== this.editedItem.passengerID &&
          e.userID !== this.editedItem.towflight.pilotID
      )
    },
    computedTowplaneList () {
      return this.$store.state.PlaneList.filter(e => e.towplane === 1)
    },
    computedAccumulatedPrice () {
      if (this.showPrice === false) return 0
      var price = parseFloat(0.00)
      if (this.flightsExternal.length > 0) {
        this.flightsExternal.forEach(element => {
          price += parseFloat(element.totalCost)
        })
      } else {
        var balance = this.$store.state.UserBalance
        if (typeof (balance) === 'object' && Object.keys(balance).length > 0 && 'flightCost' in balance) {
          price = balance.flightCost
        }
      }
      return price.toFixed(2)
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    showFlightsYear (newYear) {
      this.$store.dispatch('loadFlightList', newYear)
    }
  },
  methods: {
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    getDateFromTimestamp (stamp) {
      return moment(stamp, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD')
    },
    planeSelectEvent: function (e) {
      if (this.editedItem.planeID !== null) {
        if (this.computedPlaneHasEngine) {
          this.editedItem.launchMethod = 6
        } else {
          this.editedItem.launchMethod = 1
        }
      }
    },
    filterPilotObject (item, queryText, itemText) {
      return (item.firstname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.lastname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    },
    filterAirportObject (item, queryText, itemText) {
      return (item.icao.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.city.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    },
    moment: function () {
      return moment()
    },
    convertCommaSeperatedIntegerListToArray (string) {
      console.log(string)
      var array = []
      if (string.indexOf(',') > -1) {
        array = string.split(',')

        for (var i = 0; i < array.length; i++) {
          if (!isNaN(array[i])) {
            array[i] = parseInt(array[i])
          }
        }
      }
      return array
    },
    sliderChange () {
      this.editedItem.payerSliderSplit[0] = 0
      this.editedItem.payerSliderSplit[
        this.editedItem.payerSliderSplit.length - 1
      ] = 100

      var array = []
      for (var x = 0; x < this.editedItem.payerSliderSplit.length - 1; x++) {
        array.push(
          this.editedItem.payerSliderSplit[x + 1] -
          this.editedItem.payerSliderSplit[x]
        )
      }
      this.editedItem.paySplit = array
    },
    towSliderChange () {
      this.editedItem.towflight.payerSliderSplit[0] = 0
      this.editedItem.towflight.payerSliderSplit[
        this.editedItem.towflight.payerSliderSplit.length - 1
      ] = 100

      var array = []
      for (var x = 0; x < this.editedItem.towflight.payerSliderSplit.length - 1; x++) {
        array.push(
          this.editedItem.towflight.payerSliderSplit[x + 1] -
          this.editedItem.towflight.payerSliderSplit[x]
        )
      }
      this.editedItem.towflight.paySplit = array
    },
    updateDots () {
      console.log('upddateDotsTriggered')
      var numberOfPayers = this.editedItem.payerID.length
      var array = []
      var parts = 100 / numberOfPayers
      array.push(0)
      for (var i = 1; i <= numberOfPayers - 1; i++) {
        array.push(Math.round(100 - i * parts))
      }
      array.push(100)

      console.log(array)
      this.editedItem.payerSliderSplit = array
    },
    updateTowDots () {
      var numberOfPayers = this.editedItem.towflight.payerID.length
      var array = []
      var parts = 100 / numberOfPayers
      array.push(0)
      for (var i = 1; i <= numberOfPayers - 1; i++) {
        array.push(Math.round(100 - i * parts))
      }
      array.push(100)
      this.editedItem.towflight.payerSliderSplit = array
    },
    rightClickHandler (event, item) {
      // do something with event and/or item

      //  console.log(item)
      this.TableMenu.x = event.clientX
      this.TableMenu.y = event.clientY
      this.TableMenu.showMenu = true
      this.lastSelectedTableItem = item
    },
    TimestampToTime: function (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    calculateTimeDifference: function (takeoff, landing) {
      var ms = moment(landing).diff(moment(takeoff))
      var d = moment.duration(ms)
      return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
    },
    editLastSelectedItem () {
      this.editedIndex = this.lastSelectedTableItem.index
      this.editedItem = Object.assign({}, this.lastSelectedTableItem.item)
      this.editedItem.towflight = Object.assign({}, this.defaultItem.towflight)

      if (this.editedItem.paySplit != null && isNaN(this.editedItem.payerID)) {
        //  console.log(this.editedIndex.paySplit)
        this.editedItem.paySplit = this.convertCommaSeperatedIntegerListToArray(this.editedItem.paySplit)
      }
      var payerSliderArray = []
      var currentSliderPosition = 0
      payerSliderArray.push(0)

      if (this.editedItem.payerID != null && isNaN(this.editedItem.payerID) && this.editedItem.payerID[0] !== 'G') {
        this.editedItem.payerID = this.convertCommaSeperatedIntegerListToArray(this.editedItem.payerID)

        if (this.editedItem.payerID.length > 1) {
          for (var x = 0; x < this.editedItem.paySplit.length - 1; x++) {
            payerSliderArray.push(currentSliderPosition + this.editedItem.paySplit[x])
            currentSliderPosition += this.editedItem.paySplit[x]
          }
        }

        payerSliderArray.push(100)
      }
      this.editedItem.payerSliderSplit = payerSliderArray

      this.editedItem.takeoffTimeSelector = { HH: moment(this.editedItem.takeoffTime).format('HH'), mm: moment(this.editedItem.takeoffTime).format('mm') }
      this.editedItem.landingTimeSelector = { HH: moment(this.editedItem.landingTime).format('HH'), mm: moment(this.editedItem.landingTime).format('mm') }

      this.editedItem.manualLandTime = this.editedItem.landingTime !== null
      this.editedItem.manualToTime = this.editedItem.takeoffTime !== null

      this.dialog = true
    },
    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.lastSelectedTableItem = null
    },
    isNumber (n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    },
    planeHasEngine (callsign) {
      if (this.isNumber(callsign[2])) {
        return false
      }
      return true
    },
    formatDate (date) {
      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    recalcFlightCost () {
      this.$store.dispatch('recalculateFlightCost', this.lastSelectedTableItem.item).then(response => {
        this.$emit('toggle-data-reload')
      })
    }
  },
  mounted () {
    // DISABLE RIGHT CLICK
    document.getElementById('flighttable').addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false
    )
    this.showFlightsYear = new Date().getFullYear()
  }
}
</script>
